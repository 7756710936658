exports.scale = (ctx, text, textSize, maxWidth) => {
  const words = text.split(' ')
  let metrics
  let test
  // let scale = true
  let i
  let size = textSize
  let line = Math.floor(size * 1.61803399)

  ctx.font = `bold ${size}px/${line}px Helvetica`

  for (i = 0; i < words.length; i++) {
    test = words[i]
    metrics = ctx.measureText(test);
    while (metrics.width > maxWidth) {
      size = size - 2
      line = Math.floor(size * 1.61803399)
      ctx.font = `bold ${size}px/${line}px Helvetica`
      metrics = ctx.measureText(test);
    }
  }
}
