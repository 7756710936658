exports.background = (ctx, bgColour, width, height) => {
  ctx.fillStyle = bgColour
  ctx.fillRect(0, 0, width, height);
  ctx.save();
}

exports.tshirt = (ctx, colour, shadow) => {
  ctx.save();
  ctx.beginPath();
  ctx.moveTo(0,0);
  ctx.lineTo(363,0);
  ctx.lineTo(363,429);
  ctx.lineTo(0,429);
  ctx.closePath();
  ctx.clip();
  ctx.translate(0,0);
  ctx.translate(0,0);
  ctx.scale(1,1);
  ctx.translate(0,0);
  ctx.restore();
  ctx.save();
  ctx.restore();
  ctx.save();
  ctx.globalAlpha = 0.1
  ctx.fillStyle = shadow;
  ctx.strokeStyle = colour //"rgba(0,0,0,0)";
  ctx.lineWidth = 1;
  ctx.save();
  ctx.globalAlpha = 1.0
  ctx.translate(-74,-41);
  ctx.save();
  ctx.translate(74,41);
  ctx.save();
  ctx.save();
  ctx.globalAlpha = .7
  ctx.fillStyle = colour; // shadows
  ctx.beginPath();
  ctx.moveTo(299.1,407);
  ctx.bezierCurveTo(303.7,385.9,281.5,292.1,281.5,278.9);
  ctx.bezierCurveTo(281.5,265.7,289.5,221.4,288.1,182.5);
  ctx.bezierCurveTo(288.1,182.5,288.8,198.4,323.3,186.5);
  ctx.bezierCurveTo(357.8,174.6,365.1,168,361.2,155.5);
  ctx.bezierCurveTo(357.2,143,330.6,79.5,323.3,59.7);
  ctx.bezierCurveTo(316,39.9,307.4,35.3,279.1,24);
  ctx.bezierCurveTo(250.9,12.8,248.2,14.8,234.9,4.2);
  ctx.bezierCurveTo(234.9,4.2,181.3,20.4,134.6,1.6);
  ctx.bezierCurveTo(134.6,1.6,122.6,11.5,76.8,26);
  ctx.bezierCurveTo(31,40.5,17,107.2,7.7,131.7);
  ctx.bezierCurveTo(-1.6,156.1,-6.9,173.3,31.6,181.2);
  ctx.bezierCurveTo(70.1,189.1,70.1,177.2,70.1,177.2);
  ctx.bezierCurveTo(70.1,177.2,74.1,261.7,69.4,288.8);
  ctx.bezierCurveTo(64.8,315.9,56.5,404.4,65.1,411.6);
  ctx.bezierCurveTo(73.9,418.9,155.6,423.5,177.5,424.2);
  ctx.bezierCurveTo(229.9,425.8,294.4,428.2,299.1,407);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.globalAlpha = 1.0
  ctx.fillStyle = colour; // shirt colour
  ctx.beginPath();
  ctx.moveTo(298.2,409.8);
  ctx.bezierCurveTo(302.9,388.5,289.5,222.5,288.2,183.2);
  ctx.bezierCurveTo(288.2,183.2,288.9,199.2,323.5,187.2);
  ctx.bezierCurveTo(358.1,175.2,365.5,168.5,361.5,155.9);
  ctx.bezierCurveTo(357.5,143.2,330.8,79.2,323.5,59.2);
  ctx.bezierCurveTo(316.2,39.2,307.5,34.5,279.2,23.2);
  ctx.bezierCurveTo(250.9,11.9,248.2,13.9,234.9,3.2);
  ctx.bezierCurveTo(234.9,3.2,184.6,51.5,134.2,0.5);
  ctx.bezierCurveTo(134.2,0.5,122.2,10.5,76.2,25.2);
  ctx.bezierCurveTo(30.2,39.9,16.2,107.2,6.9,131.9);
  ctx.bezierCurveTo(-2.4,156.6,-7.8,173.9,30.9,181.9);
  ctx.bezierCurveTo(69.6,189.9,69.6,177.9,69.6,177.9);
  ctx.bezierCurveTo(69.6,177.9,54.9,407.3,63.6,414.6);
  ctx.bezierCurveTo(72.3,421.9,154.2,426.5,176.2,427.1);
  ctx.bezierCurveTo(228.8,428.7,293.5,431.1,298.2,409.8);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.globalAlpha = 0.1
  ctx.fillStyle = shadow; // shadow
  ctx.beginPath();
  ctx.moveTo(69.5,177.9);
  ctx.bezierCurveTo(69.5,177.9,45.4,133.6,38.9,107.2);
  ctx.bezierCurveTo(32.4,80.8,31.4,100.6,31.4,100.6);
  ctx.bezierCurveTo(31.4,100.6,33.2,127.9,69.5,177.9);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.globalAlpha = 0.1
  ctx.fillStyle = shadow; // shadow
  ctx.beginPath();
  ctx.moveTo(69.5,177.9);
  ctx.bezierCurveTo(69.5,177.9,53.1,120.7,52,88.1);
  ctx.bezierCurveTo(50.9,55.5,45.1,78.9,45.1,78.9);
  ctx.bezierCurveTo(45.1,78.9,40.7,111.8,69.5,177.9);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.globalAlpha = 0.1
  ctx.fillStyle = shadow; // shadow
  ctx.beginPath();
  ctx.moveTo(288.2,183.2);
  ctx.bezierCurveTo(288.2,183.2,312.5,139.1,319.9,113.7);
  ctx.bezierCurveTo(327.2,88.2,327.1,106.7,327.1,106.7);
  ctx.bezierCurveTo(327.1,106.7,324,132.6,288.2,183.2);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.save();
  ctx.globalAlpha = 0.1
  ctx.fillStyle = shadow; // shadow
  ctx.beginPath();
  ctx.moveTo(288.2,183.2);
  ctx.bezierCurveTo(288.2,183.2,305.4,126.3,308,94.1);
  ctx.bezierCurveTo(310.7,62,314.4,84.8,314.4,84.8);
  ctx.bezierCurveTo(314.4,84.8,316.5,117,288.2,183.2);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.restore();
  ctx.globalAlpha = 1.0
  ctx.save();
}

exports.logo = (ctx, dimensions, icon, iconColour, fromTop, fontFamily) => {
  const fontIcon = `0x${icon}`;
  const uni = String.fromCharCode(parseInt(fontIcon, 16));
  ctx.font = `normal 48px ${fontFamily}`
  ctx.textBaseline = 'bottom'
  ctx.fillStyle = iconColour;
  ctx.textAlign = 'center';
  // ctx.strokeStyle = "#ffffff";
  // ctx.lineWidth = 4;
  // ctx.strokeText(uni, (dimensions.width / 2), fromTop);
  ctx.fillText(uni, (dimensions.width / 2), fromTop);
  ctx.save()
}

exports.center = (ctx, width, height, layerWidth, layerHeight) => {
  ctx.translate(width / 2 - (layerWidth / 2), height / 2 - (layerHeight / 2))
}
