import React, { Component } from 'react';
import { ChromePicker } from 'react-color';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Card from 'react-bootstrap/Card'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import bestContrast from 'get-best-contrast-color'
// import ColourConverter from '../colour-converter'
import Draw from './draw'
import FontSize from './font-size'
import WrapText from './wrap-text'
import FileSaver from 'file-saver';
import faIconChars from 'font-awesome-icon-chars';
import './App.css';
import copy from 'copy-to-clipboard';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
} from 'react-share';

const { solid } = faIconChars;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      branding: true,
      fileName: 'tshirt',
      shirtColour: '#697689',
      bgColour: '#D9E3F0',
      slogan: 'tshirt.pictures',
      sloganColour: '#ffffff',
      logoUnicode: 'f553',
      logoName: 'tshirt',
      logoColour: '#ffffff',
      width: 440,
      height: 440,
      modalActive: false,
      fontAwesome: solid,
      fontAwesomeFiltered: solid,
      searchText: '',
      ctx: null,
      aspectRatio: '1:1',
      aspectRatioShape: 'square',
      validated: false,
      copyToClipBoard: 'copy link',
      copyToClipBoardSuccess: 'copied',
      copied: false,
      shareUrl: 'https://tshirt.pictures',
      shareDescription: 'Custom T-shirt image generator. Use on social media or as a placeholder image. Get creative.',
      shareHashTags: ['tshirtpictures', 'tshirt'],
      shareHashStr: '#tshirtpictures'
    };
    this.firstLoad = true;
    this.cardStyle = {
      borderWidth: '0 1px 1px 1px',
      borderRadius: '0 0 3px 3px'
    };
  }

  componentDidMount() {
    this.hydrateStateWithLocalStorage();

    // add event listener to save state to localStorage
    // when user leaves/refreshes the page
    window.addEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");
    this.setState({ctx: ctx})
  }

  componentWillUnmount() {
    window.removeEventListener(
      "beforeunload",
      this.saveStateToLocalStorage.bind(this)
    );

    // saves if component has a chance to unmount
    this.saveStateToLocalStorage();
  }

  componentDidUpdate() {
    const { ctx } = this.state;
    const { shirtColour, bgColour, slogan, sloganColour, width, height, logoUnicode, logoColour } = this.state;
    const tShirtHeight = 429;
    const tShirtWidth = 363;
    const dimensions = {
      width: width,
      height: height
    };
    ctx.clearRect(0, 0, dimensions.width, dimensions.height);
    ctx.beginPath();
    Draw.background(ctx, bgColour, dimensions.width, dimensions.height)
    Draw.center(ctx, dimensions.width, dimensions.height, tShirtWidth, tShirtHeight)
    Draw.tshirt(ctx, shirtColour, this.shirtShadow(shirtColour))

    let iconMatch = false
    // let iconHeight = 0
    const fromTop = ((dimensions.height - tShirtHeight) / 2) + 100
    if (logoUnicode) {
      const iconRegEx = /^f[0-9]{1}[a-z0-9]{2}$/
      iconMatch = logoUnicode.toLowerCase().match(iconRegEx)
      if (iconMatch) {
        if (this.firstLoad) {
          this.firstLoad = false;
          setTimeout(() => {
            Draw.logo(ctx, dimensions, logoUnicode, logoColour, fromTop, `"Font Awesome 5 Free"`)
          }, 1000)
        } else {
          Draw.logo(ctx, dimensions, logoUnicode, logoColour, fromTop, `"Font Awesome 5 Free"`)
        }
      }
    }

    const maxWidth = 200
    const textSize = 30
    const lineHeight = 36
    FontSize.scale(ctx, decodeURI(slogan), textSize, maxWidth)
    ctx.textBaseline = 'top'
    ctx.fillStyle = sloganColour
    ctx.textAlign = 'center'
    WrapText.do(
      ctx,
      slogan,
      (dimensions.width / 2) + 5,
      iconMatch ? (fromTop + 15) : 130,
      maxWidth,
      lineHeight
    )
    const contrastColours = ['#ffffff', '#f1f1f1', '#333333']
    const bestContrastColour = bestContrast(bgColour, contrastColours)
    const branding = '@invisibleloop tshirt.pictures'
    ctx.font = `normal 14px Helvetica`
    ctx.fillStyle = bestContrastColour
    ctx.textAlign = 'left'
    const brandingSize = ctx.measureText(branding)
    ctx.fillText(
      branding,
      (dimensions.width / 2) - (brandingSize.width / 2),
      dimensions.height - 25
    )
    ctx.save()
  }

  hydrateStateWithLocalStorage() {
    // for all items in state
    for (let key in this.state) {
      // if the key exists in localStorage
      if (localStorage.hasOwnProperty(key)) {
        // get the key's value from localStorage
        let value = localStorage.getItem(key);

        // parse the localStorage string and setState
        try {
          value = JSON.parse(value);
          this.setState({ [key]: value });
        } catch (e) {
          // handle empty string
          this.setState({ [key]: value });
        }
      }
    }
  }

  saveStateToLocalStorage() {
    // for every item in React state
    for (let key in this.state) {
      // save to localStorage
      localStorage.setItem(key, JSON.stringify(this.state[key]));
    }
  }

  download = () => {
    const { fileName } = this.state;
    this.refs.canvas.toBlob(function(blob) {
      FileSaver.saveAs(blob, fileName);
    });
  }

  shirtShadow(tShirtColour) {
    const contrastColoursShadow = ['rgb(255,255,255)', 'rgb(0,0,0)'];
    return bestContrast(tShirtColour, contrastColoursShadow)
  }

  imageUrl() {
    const {
      fileName,
      shirtColour,
      bgColour,
      slogan,
      sloganColour,
      logoUnicode,
      logoColour,
    } = this.state;
    return `https://tshirt.pictures/api/v1/<span class="html-value">${fileName !== '' ? fileName : 'tshirt'}</span>.png?<span class="html-key">shirtColour</span>=<span class="html-value">${shirtColour.replace('#', '')}</span>&<span class="html-key">bgColour</span>=<span class="html-value">${bgColour.replace('#', '')}</span>&<span class="html-key">slogan</span>=<span class="html-value">${slogan}</span>&<span class="html-key">sloganColour</span>=<span class="html-value">${sloganColour.replace('#', '')}</span>&<span class="html-key">logo</span>=<span class="html-value">${logoUnicode}</span>&<span class="html-key">logoColour</span>=<span class="html-value">${logoColour.replace('#', '')}</span>&<span class="html-key">aspectRatio</span>=<span class="html-value">${this.state.aspectRatio}</span>`;
  }

  linkUrl() {
    const {
      fileName,
      shirtColour,
      bgColour,
      slogan,
      sloganColour,
      logoUnicode,
      logoColour,
    } = this.state;
    return `https://tshirt.pictures/api/v1/${fileName !== '' ? fileName : 'tshirt'}.png?shirtColour=${shirtColour.replace('#', '')}&bgColour=${bgColour.replace('#', '')}&slogan=${slogan}&sloganColour=${sloganColour.replace('#', '')}&logo=${logoUnicode}&logoColour=${logoColour.replace('#', '')}&aspectRatio=${this.state.aspectRatio}`;
  }

  handleHeightKeyUp = (event) => {
    this.setState({
      height: event.target.value,
    });
  }

  handleWidthKeyUp = (event) => {
    this.setState({
      width: event.target.value,
    });
  }

  handleFileNameKeyUp = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.setState({
      validated: true,
      fileName: event.target.value,
    });
  }

  handleSloganKeyUp = (event) => {
    this.setState({
      slogan: event.target.value,
    });
  }

  handleSloganColourChange = (color) => {
    this.setState({
      sloganColour: color.hex,
    });
  }

  handleBgColourChange = (color) => {
    this.setState({
      bgColour: color.hex,
    });
  }

  handleShirtColourChange = (color) => {
    this.setState({
      shirtColour: color.hex,
    });
  }

  handleLogoKeyUp = (event) => {
    this.setState({
      logoUnicode: event.target.value,
    });
  }

  handleLogoOnChange = (event) => {
    this.setState({
      logoName: event.target.value,
    });
  }

  handleLogoColourChange = (color) => {
    this.setState({
      logoColour: color.hex,
    });
  }

  handleModalClick = () => {
    this.setState({ modalActive: true, searchText: '' });
  }

  handleModalCloseClick = () => {
    this.setState({
      modalActive: false,
      searchText: '',
      fontAwesomeFiltered: this.state.fontAwesome
    });
  }

  handleChooseIconClick = (name, unicode) => {
    this.setState({
      logoName: name,
      logoUnicode: unicode,
      modalActive: false,
      fontAwesomeFiltered: this.state.fontAwesome,
    });
  }

  handleIconSearchKeyUp = (event) => {
    const filtered = this.state.fontAwesome.filter((icon) => {
      return icon['name'].includes(event.target.value.toLowerCase());
    });
    this.setState({
      fontAwesomeFiltered: filtered,
    });
  }

  searchTextChange = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  }

  aspectRatioChange = (evt) => {
    if (evt.target.value === 'square') {
      this.setState({
        width: this.state.height,
        aspectRatio: '1:1',
        aspectRatioShape: 'square',
      });
    } else {
      this.setState({
        width: '960' ,
        aspectRatio: '16:9',
        aspectRatioShape: 'rectangle',
      });
    }
  }

  copyToClipBoardHandler = () => {;
    const str = this.linkUrl()
    copy(str);
    this.setState({copied: true});
    setTimeout(() => {
      this.setState({copied: false});
    }, 3000);
  }

  render() {
    const { validated } = this.state;
    return (
      <React.Fragment>
        <Row>
          <Col sm={12} md={7} className="pb-3">
            <div className="canvas pb-3">
              <canvas ref="canvas" width={this.state.width} height={this.state.height} />
              <ButtonGroup className="download-controls mt-3 pl-lg-3">
                <Button variant="success" onClick={ this.download }><span className="icon"><i className="fas fa-download"></i></span> download</Button>
                <Button variant="secondary" onClick={ this.copyToClipBoardHandler }><span className="icon"><i className="fas fa-clipboard"></i></span> {this.state.copied ? this.state.copyToClipBoardSuccess : this.state.copyToClipBoard}</Button>
              </ButtonGroup>
            </div>
            <Card className="mt-3">
            <Card.Body>
            <Row>
              <Col>
                <FacebookShareButton
                className="social-share-btn"
                url={this.state.shareUrl}
                quote={this.state.shareDescription}
                hashtag={this.state.shareHashStr}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
              </Col>
              <Col>
                <LinkedinShareButton className="social-share-btn" url={this.state.shareUrl}>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </Col>
              <Col>
                <TwitterShareButton
                className="social-share-btn"
                url={this.state.shareUrl}
                title={this.state.shareDescription}
                hashtags={this.state.shareHashTags}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              </Col>
              <Col>
                <RedditShareButton className="social-share-btn" url={this.state.shareUrl}>
                  <RedditIcon size={32} round={true} />
                </RedditShareButton>
              </Col>
              <Col>
                <WhatsappShareButton
                className="social-share-btn"
                url={this.state.shareUrl}
                title={this.state.shareDescription}
                separator=" | ">
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </Col>
              <Col>
                <PinterestShareButton
                className="social-share-btn"
                media={this.linkUrl()}
                url={this.state.shareUrl}
                description={this.state.shareDescription}>
                  <PinterestIcon size={32} round={true} />
                </PinterestShareButton>
              </Col>
            </Row>
            </Card.Body>
          </Card>
          </Col>
          <Col sm={12} md={5}>
            <Tabs defaultActiveKey="slogan" id="tshirtConfig">
              <Tab eventKey="slogan" title={
                <span><span className="icon"><i className="fas fa-font"></i></span></span>
              }>
              <Card style={this.cardStyle}>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Form>
                      <Form.Group controlId="slogan">
                        <h2>Slogan</h2>
                        <Form.Label>Add some text.</Form.Label>
                        <Form.Control
                          size="lg"
                          onKeyUp={this.handleSloganKeyUp.bind(this)}
                          defaultValue=""
                          type="text"
                          placeholder="e.g. Bazinga!" />
                      </Form.Group>
                    </Form>
                  </Col>
                  <Col>
                    <Form>
                      <Form.Group controlId="sloganColour">
                        <h2>Slogan colour</h2>
                        <Form.Label>Pick a text colour for your slogan</Form.Label>
                        <ChromePicker
                          color={ this.state.sloganColour }
                          width="250"
                          disableAlpha={true}
                          onChange={ this.handleSloganColourChange } />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                </Card.Body>
              </Card>
              </Tab>
              <Tab eventKey="logo" title={
                <span><span className="icon"><i className="fas fa-skull"></i></span></span>
              }>
              <Card style={this.cardStyle}>
                <Card.Body>
                <Row>
                  <Col sm={12}>
                    <h2>Logo</h2>
                    <p><b>Name:</b> {this.state.logoName} | <b>Unicode:</b> {this.state.logoUnicode}</p>
                    <Button style={{marginBottom: 20}} variant="success" onClick={this.handleModalClick}> <span className="icon"><i className="fas fa-arrow-circle-right"></i></span> Choose a new logo</Button>
                    <Modal show={this.state.modalActive} onHide={this.handleModalCloseClick}>
                      <Modal.Header closeButton>
                        <Modal.Title>Add a logo</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form>
                          <Form.Group controlId="iconList">
                            <Form.Label>Search</Form.Label>
                            <Form.Control
                              size="lg"
                              onChange={this.searchTextChange}
                              value={this.state.searchText}
                              onKeyUp={this.handleIconSearchKeyUp}
                              type="text"
                              placeholder="e.g. skull" />
                          </Form.Group>
                        </Form>
                        <Row>
                          { this.state.fontAwesomeFiltered.map((icon) => {
                            return <Col xs={3} sm={2} md={2} lg={2} key={icon.unicode}><div className="icon icon-display" onClick={() => this.handleChooseIconClick(icon.name, icon.unicode)}><i className={'fas fa-' + icon.name}></i><span className="icon-name">{ icon.name }</span></div></Col>
                          })}
                        </Row>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="success" onClick={this.handleModalCloseClick}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </Col>
                  <Col>
                    <Form>
                      <Form.Group controlId="logoColour">
                        <h2>Logo colour</h2>
                        <Form.Label>Pick a colour for your logo</Form.Label>
                        <ChromePicker
                          color={ this.state.logoColour }
                          width="250"
                          disableAlpha={true}
                          onChangeComplete={ this.handleLogoColourChange } />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                </Card.Body>
              </Card>
              </Tab>
              <Tab eventKey="tshirt" title={
                <span><span className="icon"><i className="fas fa-tshirt"></i></span></span>
              }>
              <Card style={this.cardStyle}>
              <Card.Body>
                <Form>
                  <Form.Group controlId="tshirtColour">
                    <h2>T-shirt colour</h2>
                    <Form.Label>Pick a colour for you t-shirt</Form.Label>
                    <ChromePicker
                      color={ this.state.shirtColour }
                      width="250"
                      disableAlpha={true}
                      onChangeComplete={ this.handleShirtColourChange } />
                  </Form.Group>
                </Form>
                </Card.Body>
              </Card>
              </Tab>
              <Tab eventKey="background" title={
                <span><span className="icon"><i className="fas fa-palette"></i></span></span>
              }>
              <Card style={this.cardStyle}>
              <Card.Body>
                <Form>
                  <Form.Group controlId="bgColour">
                    <h2>Background colour</h2>
                    <Form.Label>Pick a colour for the background.</Form.Label>
                    <ChromePicker
                      color={ this.state.bgColour }
                      width="250"
                      disableAlpha={true}
                      onChangeComplete={ this.handleBgColourChange } />
                  </Form.Group>
                </Form>
                </Card.Body>
              </Card>
              </Tab>
              <Tab eventKey="use" title={
                <span><span className="icon"><i className="fas fa-image"></i></span></span>
              }>
                <Card style={this.cardStyle}>
                  <Card.Body>
                  <Row>
                    <Col sm={12}>
                    <Form
                    noValidate
                    validated={validated}
                    onSubmit={ (e) => {e.preventDefault()} }>
                      <h2>Filename</h2>
                      <label htmlFor="filename">Pick a filename for the image.</label>
                      <InputGroup className="mb-3">
                        <FormControl
                          id="filename"
                          size="lg"
                          onKeyUp={this.handleFileNameKeyUp.bind(this)}
                          onChange={this.handleFileNameKeyUp.bind(this)}
                          value={this.state.fileName}
                          type="text"
                          placeholder="e.g. tshirt.picture"
                          aria-label="filename"
                          aria-describedby="filename-extension"
                          pattern="[a-zA-Z0-9\\-_\\.]+"
                          required
                        />
                        <InputGroup.Append>
                          <InputGroup.Text id="filename-extension">.png</InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </Form>
                    </Col>
                    <Col className="pb-4">
                      <h2>Download</h2>
                      <p>Download your t-shirt image as a .png.</p>
                      <Button variant="success" onClick={ this.download }><span className="icon"><i className="fas fa-download"></i></span> download</Button> | <span><b>{this.state.fileName !== '' ? this.state.fileName : 'tshirt'}</b>.png</span>
                    </Col>
                    <Col>
                      <h2>Link</h2>
                      <p>Alternatively link directly to the image on our placeholder image service.</p>
                      <div className="code-wrapper">
                        <pre className="api-link"><code style={{wordBreak: 'keep-all'}} dangerouslySetInnerHTML={{__html: this.imageUrl()}}></code></pre>
                      </div>
                      <Button variant="success" onClick={ this.copyToClipBoardHandler }><span className="icon"><i className="fas fa-clipboard"></i></span> {this.state.copied ? this.state.copyToClipBoardSuccess : this.state.copyToClipBoard}</Button>
                    </Col>
                  </Row>
                  </Card.Body>
                </Card>
              </Tab>
              <Tab eventKey="settings" title={
                <span><span className="icon"><i className="fas fa-cog"></i></span></span>
              }>
              <Card style={this.cardStyle}>
              <Card.Body>
                <Row>
                  <Col sm={12}>
                    <Form>
                      <h2>Aspect Ratio</h2>
                      <Form.Label>Choose the shape of your image</Form.Label>
                      <Form.Group controlId="aspect">
                        <Form.Check
                          inline
                          type="radio"
                          id="aspect-ratio-square"
                          name="aspect-ratio"
                          label="1:1 (square)"
                          value="square"
                          onChange={this.aspectRatioChange}
                          defaultChecked={this.state.aspectRatioShape === 'square' ? true : false}
                        />
                        <Form.Check
                          inline
                          type="radio"
                          id="aspect-ratio-rect"
                          name="aspect-ratio"
                          label="16:9 (rectangle)"
                          value="rectangle"
                          onChange={this.aspectRatioChange}
                          defaultChecked={this.state.aspectRatioShape === 'rectangle' ? true : false}
                        />
                      </Form.Group>
                    </Form>
                  </Col>
                </Row>
                </Card.Body>
              </Card>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default App;
