exports.do = (ctx, text, x, y, maxWidth, lineHeight) => {
    const words = text.split(' ')
    let line = ''
    // let lineCount = 0
    let i
    let test
    let metrics

    for (i = 0; i < words.length; i++) {
        test = words[i];
        metrics = ctx.measureText(test);
        while (metrics.width > maxWidth) {
            // Determine how much of the word will fit
            test = test.substring(0, test.length - 1);
            metrics = ctx.measureText(test);
        }
        if (words[i] !== test) {
            words.splice(i + 1, 0,  words[i].substr(test.length))
            words[i] = test;
        }

        test = line + words[i] + ' ';
        metrics = ctx.measureText(test);

        if (metrics.width > maxWidth && i > 0) {
            // ctx.strokeStyle = "#ffffff";
            // ctx.lineWidth = 4;
            // ctx.strokeText(line, x, y);
            ctx.fillText(line, x, y);
            line = words[i] + ' ';
            y += lineHeight;
            // lineCount++;
        }
        else {
            line = test;
        }
    }
    // ctx.strokeStyle = "#ffffff";
    // ctx.lineWidth = 4;
    // ctx.strokeText(line, x, y);
    ctx.fillText(line, x, y);
    ctx.save()
}
